<template>
    <main>
        <ButtonGroup shape="circle">
            <Button icon="md-refresh" @click="loadData">刷新</Button>
        </ButtonGroup>

        <Divider />

        <Table border :columns="columns" :data="data" @on-sort-change="changeSort"></Table>
    </main>
</template>

<script>
    export default {
        data()
        {
            return {
                sort   : '',
                data   : [],
                columns: [
                    {
                        title   : 'ID',
                        key     : 'id',
                        sortable: 'custom',
                        minWidth: 80
                    },
                    {
                        title   : 'KEY',
                        key     : 'key',
                        sortable: 'custom',
                        minWidth: 200,
                        render  : (h, params) => {
                            return h('p', { class: 'text-sm text-grey' }, params.row.key)
                        }
                    },
                    {
                        title   : '名称',
                        key     : 'title',
                        sortable: 'custom',
                        minWidth: 300,
                        render  : (h, params) => {
                            return h('div', [
                                        h('p', params.row.label),
                                        h('p', { class: 'text-sm text-grey' }, params.row.desc)
                                    ])
                        }
                    },
                    {
                        title : '操作',
                        width : 120,
                        align : 'center',
                        render: (h, params) => {
                            var html = []

                            if (this.Auth([ 'RuleExplain', 'set' ]))
                            {
                                html.push(h('Button', {
                                    props: {
                                        icon : 'ios-create-outline',
                                        shape: 'circle'
                                    },
                                    class: 'mg-lr-xs',
                                    on: {
                                        click: () => {
                                            this.set(params.row)
                                        }
                                    }
                                }, '设置'))
                            }

                            return h('div', html)
                        }
                    }
                ]
            }
        },
        mounted()
        {
            this.loadData()
        },
        methods: {
            loadData()
            {
                let params = {
                    sort: this.sort
                }

                this.$http.get('/ruleExplain/list', { params: params })
                    .then(res => {
                        this.data = res
                    })
            },
            changeSort(item)
            {
                this.sort = item.order == 'normal' ? '' : item.key + ',' + item.order
                this.loadData()
            },
            set(item)
            {
                this.$router.push('/rule-explain/set/' + item.id)
            }
        }
    }
</script>